<template>
<div>
  <div style="display: flex; flex-direction: row;">
<!--    <div style="display: flex; flex-direction: row;" @mousedown="start1" @mouseup="stop1" @mousemove="move1">-->
    <div :style="leftStyle">
      <div style="height: 600px;background: #6e809a">
        <el-card >
          <div slot="header" class="clearfix">
            <div style="text-align: center; font-size: 18px;">替换字串</div>
            <div style="text-align: right">
              <el-upload :action="$baseUrl + '/doc/upload'" :show-file-list="false"
                         accept=".docx,.doc"
                         :on-success="handleFileUploadSuccess" style="display: inline-block">
                <el-button type="primary">导入模板</el-button>
<!--                <el-button type="primary" class="ml-5">上传文件 <i class="el-icon-top"></i></el-button>-->
              </el-upload>
<!--              <el-button type="primary">导入模板</el-button>-->
              <el-button type="info">导入文档</el-button>
            </div>

<!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
          </div>
        </el-card>
      </div>
    </div>
    <div style="background: #e56c3c; width: 10px; cursor: col-resize"></div>
    <div :style="rightStyle">
      <el-card >
        <div slot="header" class="clearfix">
          <div style="text-align: center; font-size: 18px;">文档预览</div>
          <div>
            <iframe :src="url" width="100%" height="500"
                    ></iframe>
          </div>
          <!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
        </div>
      </el-card>
    </div>
  </div>

</div>
</template>

<script>
import { Base64 } from 'js-base64';
export default {
  name: "DocConvert",
  data(){
    return{
      leftStyle: 'width: 600px',
      rightStyle: 'width: calc(100vw - 600px) ;',
      url: '',
      styleObj: null,       // 拖拽时修改图片的样式
      isDrag: false,      // 是否开始拖拽
      startX: 0,          // 鼠标的点击X轴
      startY: 0,          // 鼠标的点击Y轴
      moveX: 0,           // 鼠标移动的X轴
      moveY: 0,            // 鼠标移动的Y轴
      endX: 0,
      endY: 0,
    }

  },
  //注册局部自定义指令
  directives: {
    //el：指令所绑定的元素
    _drag: {
      // 指令的定义
      inserted: function(el) {
        // el.drag();
        console.log(el);
        //获取元素
        // var dv = document.getElementById("dv");
        let x = 0;
        let y = 0;
        let l = 0;
        let t = 0;
        let isDown = false;
        //鼠标按下事件
        el.onmousedown = function(e) {
          //获取x坐标和y坐标
          x = e.clientX;
          y = e.clientY;
          //获取左部和顶部的偏移量
          l = el.offsetLeft;
          t = el.offsetTop;
          //开关打开
          isDown = true;
          //设置样式
          el.style.cursor = "move";
          console.log("按下：X=", x)
        };
        //鼠标移动
        window.onmousemove = function(e) {
          if (isDown == false) {
            return;
          }
          //获取x和y
          let nx = e.clientX;
          let ny = e.clientY;
          //计算移动后的左偏移量和顶部的偏移量
          let nl = nx - (x - l);
          let nt = ny - (y - t);

          el.style.left = nl + "px";
          el.style.top = nt + "px";
          if(isDown){
            console.log("按下后移动：X=", nx)
            this.leftStyle = "width: " + nx + "px"
            this.rightStyle = "width: calc(100vw - " + nx + "px)"
            console.log("按下后移动：X=", this.leftStyle)
          }
        };
        //鼠标抬起事件
        el.onmouseup = function() {
          //开关关闭
          isDown = false;
          el.style.cursor = "default";
        };
      }
    },
  },
  mounted() {
    // var url = "http://www.yz-awen.cn:9090/doc/template.docx"
    // this.url='http://www.yz-exam.cn:8012/onlinePreview?url=' + encodeURIComponent(Base64.encode((url)))
  },
  methods:{
//     start(e) {
//       // 当点击图片时，开始拖拽
//       if (e.buttons) {
//         this.isDrag = true;
//         this.startX = e.clientX;
//         this.startY = e.clientY;
//         // this.styleObj = `transform: scale(${this.multiples}) rotateZ(${this.deg}deg);left:${this.endX}px;top:${this.endY}px`;
//       }
//     },
//     stop() {
//       this.isDrag = false;
//       console.log("拖拽停止！")
//       // this.styleObj = `transform: scale(${this.multiples}) rotateZ(${this.deg}deg);left:${this.endX}px;top:${this.endY}px`;
//     },
//     move(e) {
//       // 当鼠标拖拽图片的时候，才计算移动距离
//       // 移动图片相对于父元素的位置
//       if(this.isDrag) {
//         // 鼠标移动的距离
//         this.moveX = e.clientX;
//         this.moveY = e.clientY;
//         // 相对页面的距离
//         let x=this.moveX-this.startX;
//         let y=this.moveY-this.startY;
//         // let img=document.querySelector("#look-image img");
//         // this.endX = img.offsetLeft+x;
//         // this.endY = img.offsetLeft+y;
//         // this.styleObj=`left:${this.endX}px;top:${this.endY}px`;
//         // this.styleObj = `transform: scale(${this.multiples}) rotateZ(${this.deg}deg);left:${this.endX}px;top:${this.endY}px`;
//         // 记录上次移动的距离
//         this.startX=this.moveX;
//         this.startY=this.moveY;
// var xx=this.startX
//         this.leftStyle = "width: calc(" + xx + "px - 220px)"
//         this.rightStyle = "width: calc(100vw - " + xx + "px -220px)"
//         console.log("x=",this.rightStyle)
//       }
//     },
    handleFileUploadSuccess(res) {
      console.log(res)
      this.$message.success("上传成功")
      // res.dat="http://134.175.177.189:9090/file/temp.docx"
      var myUrl="http://134.175.177.189:9090/file/8f94f218d42c45b386cd4ea8682b47c6.docx"
      alert(myUrl)
      this.url='http://www.yz-exam.cn:8012/onlinePreview?url=' + encodeURIComponent(Base64.encode((myUrl)))
      // window.open("http://www.yz-exam.cn:8012/onlinePreview?url=" + encodeURIComponent(window.btoa(res.dat)));
    },
  }
}
</script>

<style scoped>

</style>